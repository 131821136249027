// import './navigation.scss';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import EventScroller from '../../utilities/js/event-scroller';

class Navigation {
    constructor(element, options) {

        const settings = {
            initAttr: 'data-nav',
            navToggle: 'rootToggle',
            mainItemAttr: 'toggle', 
            categoryItemAttr: 'category',
            subnavItemsAttr: 'items',
            activeClassname: 'main-nav__list-item--active',
            defaultClassname: 'main-nav__list-item--default',
            visibleClassname: 'is--visible',
            hiddenClassname: 'is--hidden', 
            offset: 0
        };

        this.settings = Object.assign({}, settings, options);

        this.$navigation = element;
        this.$mainNav = this.$navigation.querySelector('[data-nav="items"]');
        this.$navToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.navToggle + '"]');
        this.$subNavItems = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.subnavItemsAttr + '"]');
        this.$mainNavItems = this.$navigation.querySelectorAll('[' + this.settings.initAttr + '="' + this.settings.mainItemAttr + '"]');
        this.$mainNavList = this.$navigation.querySelector('.main-nav__list--level-1');
        this.$mainNavListItems = this.$navigation.querySelectorAll('[data-nav-group="1"]');
        this.$siteheader = document.querySelector('.site-header');
        this.$mainNavCategories = this.$navigation.querySelectorAll('[data-nav="' + this.settings.categoryItemAttr+ '"]');
        this.isTouchDevice = this.checkDevice();
        this.viewportWidth = window.innerWidth;
    
        this.initialize();
    }

    initialize() {
        this.setEvents();
        this.calculateSubnav();
        this.calculate3rdLevelNav();
    }

    setFirstLevel (touch) {
        let $activeItem = null;

        if (touch === false) {
            this.$mainNavItems.forEach($mainNavItem => {
                if ($mainNavItem.checked === true) {
                    $activeItem = $mainNavItem;
                }
            });
            
            if ($activeItem === null) {
                const $firstItem = this.$mainNavItems[0];
                if ($firstItem) {
                    $firstItem.checked = true;
                    $firstItem.dispatchEvent(new Event('change'));
                }
            }
        } else {
            this.$mainNavListItems.forEach($mainNavListItem => {
                if ($mainNavListItem.classList.contains(this.settings.activeClassname)) {
                    $activeItem = $mainNavListItem;
                }
            });

            if ($activeItem === null) {
                const $firstListItem = this.$mainNavListItems[0];

                $firstListItem.classList.add(this.settings.defaultClassname);
                const $firstItem = $firstListItem.querySelector('[data-nav="toggle"]');
                this.handleActiveFlyout($firstItem);
            } else {
                this.handleActiveFlyout($activeItem);
            }
        }
    }

    checkDevice() {
        if (('ontouchstart' in window || navigator.maxTouchPoints > 0 && window.innerWidth <= 1024) || ((/iPhone|iPod/i).test(navigator.userAgent) || (/iPhone|iPad|iPod/i).test(navigator.userAgent) || (/(android)/i).test(navigator.userAgent))) {
            return true;
        } else {
            return false;
        }
    }

    calculateSubnav () {
        this.$subNavItems.forEach($subNavItem => {
            const $subNavChild = $subNavItem.children[0];
            if ($subNavChild) {
                $subNavItem.setAttribute('data-nav-height', $subNavChild.offsetHeight + this.settings.offset);
            }
        });
    }

    calculate3rdLevelNav () {
        this.$mainNavCategories.forEach($mainNavCategory => {
            const $thirdLevelItems = $mainNavCategory.querySelectorAll('.main-nav__subline--level-3');
            
            if ($thirdLevelItems.length > 0) {
                let thirdLevelMaxHeight = 0;
                $thirdLevelItems.forEach($thirdLevelItem => {
                    $thirdLevelItem.style.height = 'auto';
                    thirdLevelMaxHeight = $thirdLevelItem.offsetHeight > thirdLevelMaxHeight ? $thirdLevelItem.offsetHeight : thirdLevelMaxHeight; 
                });

                $thirdLevelItems.forEach($thirdLevelItem => {
                    $thirdLevelItem.style.height = thirdLevelMaxHeight + 'px';
                });
            }
        });
    }

    handleActiveFlyout ($mainNavItem) {
        if ($mainNavItem) {
            const $activeListItem = $mainNavItem.closest('[data-nav-group="1"]');
            if ($activeListItem) {
                const $activeFlyout = $activeListItem.querySelector('[data-nav="items"]');
                if ($activeFlyout) {
                    const activeFlyoutHeight = $activeFlyout.getAttribute('data-nav-height');
        
                    this.clearAllSubnavs($mainNavItem);
            
                    this.$mainNav.style.paddingBottom = activeFlyoutHeight + 'px';
                }
            }
        }
    }

    clearAllSubnavs ($mainNavItem = null, callback) {
        this.$mainNavItems.forEach($item => {
            if ($item !== $mainNavItem) {
                $item.checked = false;
            }
        });

        if ($mainNavItem === null) {
            this.$mainNav.style.paddingBottom = 0;
        }

        if (typeof callback === 'function') {
            callback();
        }
    }

    resetNavigation () {
        const $navigationToggles = this.$navigation.querySelectorAll('input[type="checkbox"]');
        $navigationToggles.forEach($navigationToggle => {
            $navigationToggle.checked = false;
            $navigationToggle.dispatchEvent(new Event('change'));
        });

        this.$navToggle.checked = false;
        this.$navToggle.dispatchEvent(new Event('change'));
    }

    setEvents() {        
        window.resizeHandler.customFunctions.push(() => {
            if (this.viewportWidth !== window.innerWidth || !this.isTouchDevice) {
                this.viewportWidth = window.innerWidth;
                this.calculateSubnav();
                this.calculate3rdLevelNav();
                this.resetNavigation();
            }
        });

        window.addEventListener('orientationchange', () => {
            this.calculateSubnav();
            this.calculate3rdLevelNav();
            this.resetNavigation();
        });

        // handles default flyout on touch devices
        this.$mainNavItems.forEach($mainNavItem => {
            $mainNavItem.addEventListener('change', () => {
                if (!Modernizr.csshover && window.mediaMatchHandler[768].matches) {
                    if ($mainNavItem.checked === true) {
                        this.handleActiveFlyout($mainNavItem);
                    } else {
                        this.clearAllSubnavs(null, () => {
                            this.setFirstLevel(false);
                        });
                    }
                }
            });
        });

        // handles body scroll lock on touch devices and closes subnav on false
        this.$navToggle.addEventListener('change', () => {            
            if (this.$navToggle.checked === true) {
                disableBodyScroll(this.$navigation);
                if (window.mediaMatchHandler[768].matches) {
                    this.setFirstLevel(Modernizr.csshover);
                }
            } else {
                this.clearAllSubnavs();
                clearAllBodyScrollLocks();
            }
        });

        // adds hover state for siteheader
        this.$navigation.addEventListener('mouseenter', () => {
            if (Modernizr.csshover) {
                this.$siteheader.classList.add('hover');
            }
        });

        this.$navigation.addEventListener('mouseleave', () => {
            if (Modernizr.csshover) {
                this.$siteheader.classList.remove('hover');
            }
        });

        this.$mainNavListItems.forEach(($mainNavlistItem) => {
            $mainNavlistItem.addEventListener('mouseenter', () => {
                if (Modernizr.csshover) {
                    const $mainNavItem = $mainNavlistItem.querySelector('[data-nav="toggle"]');

                    if ($mainNavItem) {
                        this.handleActiveFlyout($mainNavItem);
                    } else {
                        this.clearAllSubnavs();
                    }
                }
            });
        });

        if (this.$mainNavList) {
            this.$mainNavList.addEventListener('mouseleave', () => {
                if (Modernizr.csshover && this.$navToggle.checked === true) {
                    this.setFirstLevel(true);
                } else {
                    this.clearAllSubnavs();
                }
            });
        }
        
        // Handling visibility of navigation on scroll
        const eventScroller = new EventScroller({
            onScroll: (position, direction) => {
                if(position > 100) {
                    if(direction === 'down') {
                        this.$siteheader.classList.add(this.settings.hiddenClassname);
                        this.$siteheader.classList.remove(this.settings.visibleClassname);
                    } else if(direction === 'up') {
                        this.$siteheader.classList.add(this.settings.visibleClassname);
                    } 
                } else {
                    this.$siteheader.classList.remove(this.settings.hiddenClassname);
                    this.$siteheader.classList.remove(this.settings.visibleClassname);
                }
            }
        });

        eventScroller.initialize();
    }
}

export { Navigation };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const context = eventDetails.$context;

    if (context) {
        const $navigation = context.querySelector('[data-nav="root"]');
        if ($navigation) {
            const navigationAPI = new Navigation($navigation);
            $navigation.navAPI = navigationAPI;
        }
    }
});
